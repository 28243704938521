<template>
  <div v-if="data && data.length" class="products-tool">
    <public-title :data-info="titleInfo" />
    <div class="tool-bg" :style="`background: url(${data[0].image}) center center / cover no-repeat`">
      <div class="tool-box container">
        <div class="text-box">
          <h4 class="title">{{ data[0].title }}</h4>
          <p class="desc">{{ data[0].description }}</p>
          <el-button type="primary" @click="handleDownload">{{ LP.lang_start_now }}</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PublicTitle from '@/components/public/PublicTitle.vue'
export default {
  components: {
    PublicTitle
  },
  props: {
    data: {
      type: [Array, Object],
      default: () => []
    }
  },
  data() {
    return {
      titleInfo: {
        title: this.LP.lang_tool
      }
    }
  },
  methods: {
    // 下载
    handleDownload() {
      window.open(this.data[0].tool_link, '_blank')
    }
  }
}
</script>

<style lang="less" scoped>
.products-tool {
  .tool-bg {
    height: 400px;
    .tool-box {
      height: 100%;
      position: relative;
      .text-box {
        width: 36%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        .title {
          font-size: 24px;
          font-weight: bold;
          color: #232931;
          line-height: 32px;
        }
        .desc {
          font-size: 14px;
          color: #666666;
          line-height: 19px;
          margin: 46px 0 50px 0;
        }
      }
    }
  }
}
</style>
