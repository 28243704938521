<template>
  <div v-if="showBtn === '1'" class="products-slogan">
    <public-title :data-info="titleInfo" />
    <div class="slogan-box">
      <el-button type="primary" @click="clickHandle">{{ LP.lang_contact_us }}</el-button>
    </div>
  </div>
</template>

<script>
import PublicTitle from '@/components/public/PublicTitle.vue'
export default {
  components: {
    PublicTitle
  },
  props: {
    showBtn: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      titleInfo: {
        title: this.LP.lang_slogan_reminder_contact
      }
    }
  },
  methods: {
    clickHandle() {
      this.$router.push({
        path: `/${this.$route.params.site}/${this.$route.params.lang}/contactUs`
      })
    }
  }
}
</script>

<style lang="less" scoped>
.products-slogan {
  .slogan-box {
    margin-top: 24px;
    text-align: center;
  }
  @media screen and (max-width: 768px) {
    /deep/ .public-title {
      .text-box {
        padding: 40px 0 17px;
        .title {
          line-height: 42px;
        }
      }
    }
    .slogan-box {
      margin-top: 0;
    }
  }
}
</style>
