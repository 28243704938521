<template>
  <div class="products-list">
    <public-bread-crumb :bread-crumb="navigator" />
    <!-- 二级导航 -->
    <div class="products-subnav">
      <div class="subnav-box container">
        <span class="subnav-title" @click="goBackHandle">
          <i class="iconfont icon-a-zuojiantou1x"></i>
          {{ title }}
        </span>
        <span class="right-icon" @click="handleScreen()">
          <i class="iconfont icon-filter"></i>
        </span>
      </div>
    </div>
    <div class="list-container container">
      <!-- 侧边栏pc端 -->
      <div class="sidebar-box">
        <div class="sidebar-reset-btn">
          <a class="btn-box" @click="handleNavReset">{{ LP.lang_reset }}</a>
        </div>
        <!-- 一级菜单 -->
        <div
          v-for="(item, index) in sidebarList"
          :key="index"
          class="sidebar-item"
          :class="{ active: currentIndex === index }"
        >
          <div class="text-box" @click="handleLevelMenu(index)">
            <div class="text-left">
              <span class="title">{{ item.search_name }}</span>
              <span v-if="item.children && item.children.length" class="number">({{ item.children.length }})</span>
            </div>
            <div class="icon-right">
              <i class="iconfont" :class="currentIndex === index ? 'icon-a-icon-expand1x' : 'icon-a-icon-close1x'"></i>
            </div>
          </div>
          <!-- 二级菜单 -->
          <el-collapse-transition>
            <ul v-if="item.children.length && currentIndex === index" class="child-list">
              <el-checkbox
                v-model="checkAll[index]"
                :indeterminate="isIndeterminate[index]"
                @change="handleCheckAllChange"
              >
                {{ LP.lang_all }}
              </el-checkbox>
              <el-checkbox-group v-model="checked[index]" @change="handleCheckedChange">
                <el-checkbox
                  v-for="(itemChild, indexChild) in item.children"
                  :key="indexChild"
                  :label="itemChild.search_id"
                >
                  {{ itemChild.search_name }}
                </el-checkbox>
              </el-checkbox-group>
            </ul>
          </el-collapse-transition>
        </div>
      </div>
      <!-- 侧边栏移动端 -->
      <transition v-if="sidebarList && sidebarList.length" name="el-fade-in-linear">
        <sidebar-mobile
          v-show="showSidebarMobile"
          :sidebar-list="sidebarList"
          :current-index="currentIndex"
          :check-all="checkAll"
          :checked="checked"
          :is-indeterminate="isIndeterminate"
          @handleNavReset="handleNavReset"
          @handleLevelMenu="handleLevelMenu"
          @handleCheckAllChange="handleCheckAllChange"
          @handleCheckedChange="handleCheckedChange"
          @close="handleCloseScreen"
        />
      </transition>
      <!-- 产品数据 -->
      <div
        v-loading="loading"
        class="right-box"
        :element-loading-text="LP.lang_loading"
        element-loading-spinner="el-icon-loading"
      >
        <div class="content-list">
          <div v-for="(item, index) in contentList" :key="index" class="content-item">
            <div class="img-bg">
              <div class="compare">
                <el-checkbox-group v-model="comparecChecked" @change="handleComparecChecked">
                  <el-checkbox :label="item.product_id">{{ LP.lang_compare }}</el-checkbox>
                </el-checkbox-group>
              </div>
              <div class="img-box">
                <a
                  :href="`/${$route.params.site}/${$route.params.lang}/products/productDetail?product_id=${item.product_id}`"
                >
                  <img :src="item.product_thumb" alt="" />
                </a>
              </div>
            </div>
            <div class="text-box">
              <div class="compare">
                <el-checkbox-group v-model="comparecChecked" @change="handleComparecChecked">
                  <el-checkbox :label="item.product_id">{{ LP.lang_compare }}</el-checkbox>
                </el-checkbox-group>
              </div>
              <div class="text-content">
                <a
                  :href="`/${$route.params.site}/${$route.params.lang}/products/productDetail?product_id=${item.product_id}`"
                >
                  <div class="title-box">
                    <span class="title ellipsis">{{ item.product_name }}</span>
                    <i class="iconfont icon-a-youjiantou1x"></i>
                  </div>
                  <p class="desc">{{ item.product_description }}</p>
                </a>
              </div>
            </div>
          </div>
        </div>
        <!-- 分页 -->
        <div v-if="total > 10" class="public-pagination">
          <el-pagination
            background
            layout="prev, pager, next"
            :total="total"
            :page-size="10"
            @current-change="handleCurrentChange"
          />
        </div>
      </div>
      <!-- 产品对比弹窗 -->
      <transition name="el-fade-in-linear">
        <div v-if="compareList && compareList.length" class="compare-bottom">
          <div class="compare-container container">
            <ul class="compare-list">
              <li v-for="(item, index) in compareList" :key="index" class="items">
                <div class="img-box">
                  <img :src="item.product_thumb" alt="" class="compare-img" />
                  <i class="iconfont icon-shanchu_1" @click="handleDeleteSelected(item)"></i>
                </div>
                <p class="text-box ellipsis">{{ item.product_name }}</p>
              </li>
            </ul>
            <div class="compare-operate">
              <div class="compare-products">
                <h5 class="name">{{ LP.lang_compare_products }}</h5>
                <div class="btn-box">
                  <el-button type="primary" @click="handleComparePage">{{ LP.lang_compare }}</el-button>
                  <el-button type="info" @click="handleReset">{{ LP.lang_reset }}</el-button>
                </div>
              </div>
              <div class="arrow-box" @click="handleHideCompare">
                <i class="iconfont icon-a-xiala1x"></i>
              </div>
            </div>
          </div>
        </div>
      </transition>
      <!-- 产品对比弹框移动端 -->
      <transition v-if="compareList && compareList.length">
        <compare-pop-mobile
          class="show-compare-mobile"
          :list="compareList"
          @handleHideCompare="handleHideCompare"
          @handleComparePage="handleComparePage"
          @handleReset="handleReset"
        />
      </transition>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import PublicBreadCrumb from '@/components/public/PublicBreadCrumb.vue'
import { productSelectorApi } from '@/api/products'
import ComparePopMobile from '@/components/products/productSelector/ComparePopMobile.vue'
import SidebarMobile from '@/components/products/productSelector/SidebarMobile.vue'
export default {
  components: {
    PublicBreadCrumb,
    ComparePopMobile,
    SidebarMobile,
  },
  data() {
    return {
      breadCrumb: [],
      sidebarList: [], // 侧边栏
      checkAll: [], // 侧边栏全选
      checked: [], // 侧边栏每一项选择
      isIndeterminate: [], // 选中一部分的横线
      currentIndex: -1, // 一级菜单当前展开
      contentList: [], // 产品列表
      isCompared: false, // 是否展示产品对比弹窗
      comparecChecked: [], // 选中需要对比的的产品
      compareList: [], // 已选择的对比产品
      productsIds: [], // 当前已选择对比产品id
      total: 0, // 总条数
      loading: false,
      title: '', // 面包屑下方标题
      showSidebarMobile: false, // 是否显示移动端侧边栏
    }
  },
  computed: {
    ...mapState({
      whiteHeader: (state) => state.header.whiteHeader,
      navigator: (state) => state.common.navigator,
    }),
  },
  watch: {
    $route(val, oldVal) {
      if (val.query !== oldVal.query) {
        this.handleNavReset()
        window.sessionStorage.removeItem('checkboxFilter')
      }
      this.$store.dispatch('common/getNavigator', { type: 'product_menu', id: val.query.menu_id, level: 4 })
      this.handleData(val.query.menu_id)
    },
    compareList: function () {
      if (window) {
        window.sessionStorage.setItem(
          'productSelectedList',
          JSON.stringify({
            compareList: this.compareList,
          }),
        )
      }
    },
  },
  async mounted() {
    this.$store.commit('header/SET_WHITE_HEADER', true)
    await this.handleData(this.$route.query.menu_id)
    // 初始化侧边栏选中
    this.sidebarList.forEach((item) => {
      this.checkAll.push(false)
      this.checked.push([])
      this.isIndeterminate.push(false)
    })
    await this.$store.dispatch('common/getNavigator', { type: 'product_menu', id: this.$route.query.menu_id, level: 4 })
    this.$nextTick(function () {
      // 初始化 id
      if (window) {
        const checkboxFilter = JSON.parse(window.sessionStorage.getItem('checkboxFilter'))
        const productSelectedList = JSON.parse(window.sessionStorage.getItem('productSelectedList'))
        this.handleData(this.$route.query.menu_id, checkboxFilter && checkboxFilter.checked[0])
        if (checkboxFilter) {
          this.checked = checkboxFilter.checked
          this.checkAll = checkboxFilter.checkAll
          this.isIndeterminate = checkboxFilter.isIndeterminate
        }
        if (productSelectedList) {
          this.compareList = productSelectedList.compareList
          const arr = []
          productSelectedList.compareList.forEach((item) => {
            arr.push(item.product_id)
          })
          this.comparecChecked = arr
        }
      }
    })
  },
  destroyed() {
    this.handleNavReset()
    window.sessionStorage.removeItem('checkboxFilter')
  },
  methods: {
    // 接口数据
    async handleData(menu_id, searchIds = [], page = 1, limit = 10) {
      this.loading = true
      await productSelectorApi({
        menu_id,
        searchIds: searchIds,
        page: page,
        limit: limit,
        type: '',
        child: '',
        product_id: '',
      })
        .then((res) => {
          this.$store.commit('common/SET_MATE', res.data.seo)
          this.sidebarList = res.data.searchList // 筛选项列表
          this.contentList = res.data.productList // 产品列表
          this.total = res.data.productCount // 总条数
          this.title = res.data.product_menu_name
          this.loading = false
        })
        .catch((err) => {
          console.log(err)
          this.loading = false
        })
    },
    // 一级菜单展开折叠
    handleLevelMenu(index) {
      this.currentIndex = this.currentIndex === index ? -1 : index
    },
    // 隐藏产品对比弹窗
    handleHideCompare() {
      this.isCompared = false
    },
    // 侧边栏全选筛选
    handleCheckAllChange(val) {
      this.sidebarList.forEach((item, index) => {
        if (this.currentIndex === index) {
          this.$set(this.checked, index, val ? item.children.map((i) => i.search_id) : [])
          this.isIndeterminate[index] = false
          // 选中id集合
          let arr = []
          this.checked.forEach((item) => {
            arr = [...arr, ...item]
          })
          // 调用接口
          productSelectorApi({
            menu_id: this.$route.query.menu_id,
            searchIds: arr,
          })
            .then((res) => {
              this.sidebarList = res.data.searchList // 筛选项列表
              this.contentList = res.data.productList // 产品列表
            })
            .catch((err) => {
              console.log(err)
            })
          window.sessionStorage.setItem(
            'checkboxFilter',
            JSON.stringify({
              checked: this.checked,
              checkAll: this.checkAll,
              isIndeterminate: this.isIndeterminate,
            }),
          )
        }
      })
    },
    // 侧边栏单项筛选
    async handleCheckedChange(value) {
      this.sidebarList.forEach((item, index) => {
        if (this.currentIndex === index) {
          this.$set(this.checkAll, index, value.length === item.children.length)
          this.$set(this.isIndeterminate, index, value.length > 0 && value.length < item.children.length)
          // 选中id集合
          let arr = []
          this.checked.forEach((item) => {
            arr = [...arr, ...item]
          })
          // 调用接口
          productSelectorApi({
            menu_id: this.$route.query.menu_id,
            searchIds: arr,
          })
            .then((res) => {
              this.sidebarList = res.data.searchList // 筛选项列表
              this.contentList = res.data.productList // 产品列表
            })
            .catch((err) => {
              console.log(err)
            })
          window.sessionStorage.setItem(
            'checkboxFilter',
            JSON.stringify({
              checked: this.checked,
              checkAll: this.checkAll,
              isIndeterminate: this.isIndeterminate,
            }),
          )
        }
      })
    },
    // 选择需要对比的产品
    handleComparecChecked(value) {
      let currentItem = {}
      this.compareList = []
      value.forEach((b) => {
        this.contentList.forEach((item) => {
          if (b === item.product_id) {
            currentItem = {
              product_thumb: item.product_thumb,
              product_id: item.product_id,
              product_name: item.product_name,
            }
          }
          this.compareList.push(currentItem)
          // 数组去重
          const res = []
          for (var i = 0; i < this.compareList.length; i++) {
            if (!res.find((item) => item.product_id === this.compareList[i].product_id)) {
              if (Object.keys(this.compareList[i]).length > 0) {
                res.push(this.compareList[i])
              }
            }
          }
          this.compareList = res
        })
      })
      this.isCompared = value.length > 0
      // 如果长度大于6，则删除第一个数组
      if (value.length > 6) this.compareList.shift()
    },
    // 删除已选择的产品
    handleDeleteSelected(item) {
      const compareListLen = this.compareList.length
      for (let i = 0; i < compareListLen; i++) {
        if (this.compareList[i].title === item.title) {
          // 判断数组中是否已包含当前值，包含则删除
          this.compareList.splice(i, 1)
          const arr = []
          this.compareList.forEach((item) => {
            arr.push(item.product_id)
          })
          this.comparecChecked = arr
          if (this.comparecChecked.length <= 0) {
            this.isCompared = false
          } else {
            return
          }
        }
      }
    },
    // 跳转产品对比页
    handleComparePage() {
      this.compareList.forEach((item) => {
        this.productsIds = Array.from(new Set(this.productsIds.concat(item.product_id)))
      })
      this.$router.push({
        path: `/${this.$route.params.site}/${this.$route.params.lang}` + '/products/productSelector/compare',
        query: {
          menu_id: this.$route.query.menu_id,
          id: this.productsIds.toString(),
        },
      })
    },
    // 清空已选择的对比产品
    handleReset() {
      this.compareList = []
      this.comparecChecked = []
      this.isCompared = false
    },
    // 跳转详情页
    // detailHandle(item) {
    //   this.$router.push({
    //     path: `/${this.$route.params.site}/${this.$route.params.lang}/products/productDetail`,
    //     query: {
    //       product_id: item.product_id,
    //     },
    //   })
    // },
    // 清空侧边栏筛选
    handleNavReset() {
      this.currentIndex = -1
      this.checked.forEach((item, index) => {
        this.checkAll[index] = false
        this.checked[index] = []
        this.isIndeterminate[index] = false
      })
      this.handleData(this.$route.query.menu_id)
    },
    // 分页切换
    handleCurrentChange(val) {
      // 选中id集合
      let arr = []
      this.checked.forEach((item) => {
        arr = [...arr, ...item]
      })
      this.handleData(this.$route.query.menu_id, arr, val)
    },
    // 移动端头部标题返回上一页
    goBackHandle() {
      this.$router.back()
    },
    // 移动端显示筛选弹窗
    handleScreen() {
      this.showSidebarMobile = true
    },
    // 移动端关闭筛选弹窗
    handleCloseScreen() {
      this.showSidebarMobile = false
    },
  },
}
</script>

<style lang="less" scoped>
@import '~@/styles/common/common.less';
.products-list {
  min-height: 600px;
  margin-top: 80px;
  padding-bottom: 48px;
  background: @background;
  border-top: 1px solid #ddd;
  .products-subnav {
    border-bottom: 1px solid #dddddd;
    padding: 9px 0 16px 0;
    background: #fff;
    .subnav-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .subnav-title {
        font-size: 18px;
        font-weight: bold;
        color: #232931;
        line-height: 24px;
        .iconfont {
          display: none;
          color: #979797;
        }
      }
      .right-icon {
        display: none;
        width: 32px;
        height: 32px;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        border: 1px solid #dddddd;
        .iconfont {
          font-size: 28px;
          color: #363739;
        }
      }
    }
  }
  .list-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 24px;
    // 侧边栏
    .sidebar-box {
      width: 308px;
      background: #ffffff;
      padding: 0 20px;
      margin-right: 16px;
      .sidebar-reset-btn {
        padding: 16px 0 10px 0;
        border-bottom: 1px solid #dddddd;
        text-align: left;
        .btn-box {
          display: inline-block;
          font-size: 14px;
          font-weight: 600;
          line-height: 16px;
          padding: 4px 15px;
          border-radius: 16px;
          border: 1px solid #cccccc;
          cursor: pointer;
          .transition();
          &:hover {
            color: #fff;
            background-color: @theme;
            border: 1px solid @theme;
          }
        }
      }
      .sidebar-item {
        cursor: pointer;
        .text-box {
          display: flex;
          justify-content: space-between;
          padding: 12px 0;
          border-bottom: 1px solid #dddddd;
          .text-left {
            .title {
              font-size: 15px;
              font-weight: 600;
              color: #333333;
              line-height: 16px;
            }
            .number {
              font-size: 14px;
              color: #666666;
              line-height: 16px;
              margin-left: 10px;
            }
          }
          .icon-right {
            & > .iconfont {
              font-size: 16px;
            }
          }
        }
        .child-list {
          padding: 24px 16px;
          /deep/ .el-checkbox {
            width: 100%;
            font-size: 14px;
            color: #666666;
            line-height: 16px;
          }
          /deep/ .el-checkbox + .el-checkbox {
            margin-top: 16px;
          }
          /deep/ .el-checkbox-group {
            margin-top: 16px;
          }
        }
        .active {
          background: #ccc;
        }
      }
    }
    // 产品数据
    .right-box {
      width: calc(100% - 324px);
      .content-list {
        display: flex;
        flex-wrap: wrap;
        .content-item {
          width: calc((100% - 32px) / 3);
          margin: 0 16px 16px 0;
          background: #fff;
          &:nth-child(3n) {
            margin-right: 0;
          }
          .img-bg {
            padding: 8px 16px;
            border-bottom: 1px solid #e4e7ed;
            .compare {
              text-align: right;
            }
            .img-box {
              text-align: center;
              padding: 8px 0;
              cursor: pointer;
              & > a {
                max-width: 100%;
                display: block;
                & > img {
                  max-width: 100%;
                  .transition;
                }
              }
            }
          }
          .text-box {
            cursor: pointer;
            padding: 8px 16px;
            .compare {
              display: none;
              text-align: right;
              margin-bottom: 5px;
            }
            .text-content {
              .title-box {
                display: flex;
                justify-content: space-between;
                align-items: center;
                .title {
                  font-size: 18px;
                  color: #333333;
                  line-height: 24px;
                  .transition;
                }
                & > .iconfont {
                  color: #979797;
                  font-size: 18px;
                }
              }
              .desc {
                font-size: 14px;
                color: #666666;
                line-height: 19px;
                margin-top: 8px;
                height: 40px;
                overflow: hidden;
                word-break: break-word;
              }
            }
          }
          &:hover {
            .img-bg {
              .img-box {
                img {
                  .scale;
                }
              }
            }
            .text-box {
              .title-box {
                .title {
                  color: @theme;
                }
              }
            }
          }
        }
      }
      .public-pagination {
        display: none;
      }
    }
    // 产品对比弹窗
    .compare-bottom {
      width: 100%;
      min-height: 180px;
      background: rgba(35, 41, 49, 0.85);
      position: fixed;
      bottom: 0;
      left: 0;
      z-index: 999;
      .compare-container {
        padding: 24px 0;
        display: flex;
        align-items: center;
        .compare-list {
          width: 75%;
          display: flex;
          .items {
            width: calc((100% - 80px) / 6);
            margin-right: 16px;
            background: #fff;
            .img-box {
              padding: 0 24px;
              position: relative;
              .compare-img {
                max-width: 100%;
              }
              & > .iconfont {
                color: #909399;
                font-size: 16px;
                padding: 4px;
                position: absolute;
                right: 0;
                top: 0;
                cursor: pointer;
              }
            }
            .text-box {
              font-size: 14px;
              color: #333333;
              line-height: 19px;
              border-top: 1px solid #dddddd;
              padding: 6px;
              text-align: center;
            }
            &:last-child {
              margin-right: 0;
            }
          }
        }
        .compare-operate {
          width: calc(25% - 66px);
          margin-left: 66px;
          display: flex;
          justify-content: end;
          .compare-products {
            .name {
              font-size: 18px;
              font-weight: bold;
              color: #ffffff;
              line-height: 24px;
            }
            .btn-box {
              margin-top: 40px;
              /deep/ .el-button--primary {
                color: @theme;
                background-color: #fff;
                border-color: #fff;
              }
            }
          }
          .arrow-box {
            margin-left: 16px;
            & > .iconfont {
              font-size: 24px;
              color: #fff;
              cursor: pointer;
            }
          }
        }
      }
    }
    // 手机端产品对比弹窗
    .show-compare-mobile {
      display: none;
    }
  }
  @media screen and (max-width: 1366px) {
    .list-container {
      .compare-bottom {
        .compare-container {
          .compare-list {
            width: 70%;
          }
          .compare-operate {
            width: calc(30% - 30px);
            margin-left: 30px;
          }
        }
      }
    }
  }
  @media screen and (max-width: 1200px) {
    .list-container {
      .right-box {
        .content-list {
          .content-item {
            width: calc((100% - 16px) / 2);
            &:nth-child(3n) {
              margin: 0 16px 16px 0;
            }
            &:nth-child(2n) {
              margin-right: 0;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 768px) {
    margin-top: 93px;
    .products-subnav {
      .subnav-box {
        .subnav-title {
          .iconfont {
            display: inline-block;
          }
        }
        .right-icon {
          display: flex;
        }
      }
    }
    .list-container {
      .sidebar-box {
        display: none;
      }
      .right-box {
        width: 100%;
        .content-list {
          display: block;
          .content-item {
            width: 100%;
            margin: 0;
            margin-bottom: 10px;
            display: flex;
            .img-bg {
              width: 35%;
              padding: 10px 0 0;
              .compare {
                display: none;
              }
              .img-box {
                padding: 0;
                a {
                  & > img {
                    max-width: 120px;
                  }
                }
              }
            }
            .text-box {
              width: 65%;
              .compare {
                display: block;
              }
            }
          }
        }
      }
      .compare-bottom {
        display: none;
      }
      // 手机端产品对比弹窗
      .show-compare-mobile {
        display: block;
      }
    }
  }
}
</style>
