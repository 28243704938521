<template>
  <div v-if="data && data.length" class="products-service">
    <public-title :data-info="titleInfo" />
    <div class="service container">
      <div class="img-box" :style="`background: url(${data[0].image}) center center / cover no-repeat`">
      </div>
      <div class="text-box">
        <h5 class="title">{{ data[0].name }}</h5>
        <p class="desc">{{ data[0].content }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import PublicTitle from '@/components/public/PublicTitle.vue'
export default {
  components: {
    PublicTitle
  },
  props: {
    data: {
      type: [Array, Object],
      default: () => []
    }
  },
  data() {
    return {
      titleInfo: {
        title: this.LP.lang_service
      }
    }
  }
}
</script>

<style lang="less" scoped>
.products-service {
  .service {
    display: flex;
    align-items: center;
    .img-box {
      width: 46%;
      max-width: 578px;
      height: 320px;
      border-radius: 10px;
    }
    .text-box {
      width: 54%;
      padding: 0 70px;
      .title {
        font-size: 24px;
        font-weight: bold;
        color: #232931;
        line-height: 32px;
      }
      .desc {
        font-size: 14px;
        color: #666666;
        line-height: 19px;
        margin-top: 46px;
      }
    }
  }
   @media screen and (max-width: 768px) {
      /deep/ .public-title {
      .text-box {
        padding: 40px 0 15px;
      }
    }
    .service {
      display: block;
      .img-box {
        width: 100%;
        height: 186px;
      }
      .text-box {
        width: 100%;
        padding: 10px 0 0;
        .title {
          font-size: 16px;
          line-height: 16px;
        }
        .desc {
          margin-top: 10px;
        }
      }
    }
  }
}
</style>
