<template>
  <div class="products-compare">
    <div class="container">
      <public-title :data-info="compareTitle" />
      <div class="operation-box">
        <div class="left-box">
          <el-button type="primary" @click="handleBack">{{ LP.lang_go_back }}</el-button>
        </div>
        <div class="right-box">
          <el-switch
            v-model="value"
            active-color="#0E5FAC"
            inactive-color="#909399"
            :active-text="LP.lang_see_differences_only"
            class="switch"
            @change="handleChange"
          />
          <button class="save" @click="handleSave">
            <span class="iconfont icon-pdf"></span>
            {{ LP.lang_save_result_as_pdf }}
          </button>
        </div>
      </div>
      <div class="compare-table-wrap">
        <div class="compare-table">
          <table width="100%">
            <tr>
              <td>{{ LP.lang_images }}</td>
              <td v-for="(item, index) in productList" :key="index">
                <router-link :to="`/${$route.params.site}/${$route.params.lang}/products/productDetail?product_id=${item.product_id}`">
                  <img :src="item.product_thumb" />
                  <div v-html="item.model_name"></div>
                </router-link>
              </td>
            </tr>
            <tr>
              <td class="table-title title-sub" :colspan="productList.length + 1">{{ LP.lang_properties }}</td>
            </tr>
            <tr
              v-for="(item, index) in attritionList"
              :key="index"
              :class="{'row-spe': item.equal === '0'}"
            >
              <td v-html="item.attribute_name"></td>
              <td
                v-for="(subitem, subindex) in item.children"
                :key="subindex"
                v-html="subitem.attribute_name"
              >
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import PublicTitle from '@/components/public/PublicTitle.vue'
import { productCompareApi } from '@/api/products'
export default {
  components: { PublicTitle },
  data() {
    return {
      compareTitle: {
        title: this.LP.lang_compare_products
      },
      value: false, // 是否隐藏相同项
      productList: [], // 产品图
      attritionList: [], // 产品参数
      pdfLink: '' // 下载pdf地址
    }
  },
  computed: {
    ...mapState({
      whiteHeader: (state) => state.header.whiteHeader
    })
  },
  mounted() {
    this.$store.commit('header/SET_WHITE_HEADER', true)
    this.hanldleData(this.$route.query.id, 0, '', this.$route.query.menu_id)
  },
  methods: {
    // 接口数据
    async hanldleData(compare, contrast, type, menuId) {
      await productCompareApi({
        compare: compare,
        contrast: contrast,
        type: type,
        menu_id: menuId
      }).then((res) => {
        this.$store.commit('common/SET_MATE', res.data.seo)
        this.productList = res.data.productList
        this.attritionList = res.data.attritionList
        this.pdfLink = res.data.product_selector_pdf || ''
      }).catch((err) => {
        console.log(err)
      })
    },
    // 返回上一页
    handleBack() {
      this.$router.push({
        path: `/${this.$route.params.site}/${this.$route.params.lang}` + '/products/productSelector',
        query: {
          menu_id: this.$route.query.menu_id
        }
      })
    },
    // 将对比结果保存为pdf格式
    async handleSave() {
      await this.hanldleData(this.$route.query.id, 0, 'pdf', this.$route.query.menu_id)
      window.open(this.pdfLink)
    },
    // 隐藏相同参数
    handleChange(val) {
      // 调用产品对比接口
      this.hanldleData(this.$route.query.id, this.value ? 1 : 0, '', this.$route.query.menu_id)
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/styles/common/common';
.products-compare {
  margin-top: 80px;
  padding-bottom: 72px;
  background: @background;
  .operation-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .right-box {
      /deep/ .el-switch__label * {
        font-size: 16px;
      }
      /deep/ .el-switch__label.is-active {
        color: #303133;
      }
      .save {
        font-size: 16px;
        color: #303133;
        line-height: 21px;
        padding: 6px 16px;
        background: #fff;
        border: 1px solid #E8E8E8;
        border-radius: 5px;
        margin-left: 24px;
        .transition(0.3s);
        &>.iconfont {
          font-size: 18px;
          margin-right: 6px;
        }
        &:hover {
          cursor: pointer;
          background-color: #f5f5f5;
        }
      }
    }
  }
  .compare-table-wrap {
    .compare-table {
      width: 100%;
      margin-top: 24px;
      table {
        table-layout: fixed;
        background-color: #fff;
        td {
          font-size: 14px;
          padding: 10px 32px;
          border-bottom: solid 1px #eee;
          word-break: break-word;
          img {
            width: 100%;
            max-width: 200px;
          }
        }
        .table-title {
          font-weight: 600;
        }
        .title-sub {
          background-color: #eee;
        }
        .row-spe {
          td {
            background-color: #fff8d6;
          }
        }
      }
    }
  }
  @media screen and (max-width: 768px) {
    .operation-box {
      flex-wrap: wrap;
      .right-box {
        display: flex;
        flex-wrap: wrap;
        margin-top: 16px;
        .save {
          margin: 16px 0 0 0;
        }
      }
    }
    .compare-table-wrap {
      overflow-x: auto;
      .compare-table {
        min-width: 840px;
      }
    }
  }
}
</style>
