<template>
  <div class="products-wrap">
    <banner :id="dataInfo.banner_menu_id" />
    <public-bread-crumb :bread-crumb="navigator" />
    <!-- 二级导航 -->
    <div class="detail-subnav" :class="{'subnav-fixed' : subnavTop >= 500, 'subnav-top': subnavUp === true}">
      <div class="subnav-box container">
        <div class="left-title">
          {{ dataInfo.menu_name }}
        </div>
        <ul class="right-list">
          <li v-for="(item, index) in tabsList" :key="index" class="list-item" @click="handleScroll(item.anchor)">{{ item.title }}</li>
        </ul>
      </div>
    </div>
    <why-choose-us id="product_modular_one" :data="dataInfo && dataInfo.choose_us" />
    <product-categories id="product_modular_two" :data="dataInfo && dataInfo.second_product_menu" />
    <tool v-if="$route.params.site === 'business'" id="product_modular_three" :data="dataInfo && dataInfo.tools" />
    <videos id="product_modular_four" :data="dataInfo && dataInfo.video" />
    <honors-awards id="product_modular_five" :data="dataInfo && dataInfo.honors_awards" />
    <service id="product_modular_six" :data="dataInfo && dataInfo.service" />
    <products-download id="product_modular_seven" :data="dataInfo && dataInfoDownload" />
    <slogan :show-btn="dataInfo && dataInfo.contact_us" />
    <success-cases id="product_modular_eight" :data="dataInfo && dataInfo.success_cases" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Banner from '@/components/products/productCategories/Banner'
import PublicBreadCrumb from '@/components/public/PublicBreadCrumb.vue'
import WhyChooseUs from '@/components/products/productCategories/WhyChooseUs.vue'
import ProductCategories from '@/components/products/productCategories/ProductCategories.vue'
import Tool from '@/components/products/productCategories/Tool.vue'
import Videos from '@/components/products/productCategories/Videos.vue'
import HonorsAwards from '@/components/products/productCategories/HonorsAwards.vue'
import ProductsDownload from '@/components/products/productCategories/ProductsDownload.vue'
import Slogan from '@/components/products/productCategories/Slogan.vue'
import SuccessCases from '@/components/products/productCategories/SuccessCases.vue'
import { scrollToTop, calculateTop } from '@/utils/utils.js'
import { productCategoryApi } from '@/api/products'
import Service from '@/components/products/productCategories/Service.vue'
export default {
  name: 'Products',
  components: {
    Banner,
    PublicBreadCrumb,
    WhyChooseUs,
    ProductCategories,
    Tool,
    Videos,
    HonorsAwards,
    ProductsDownload,
    Slogan,
    SuccessCases,
    Service
  },
  data() {
    return {
      dataInfo: {}, // 接口数据
      dataInfoDownload: {}, // 下载接口数据
      tabsList: [], // 二级导航列表
      subnavTop: 0,
      subnavUp: false,
      i: 0
    }
  },
  computed: {
    ...mapState({
      navigator: state => state.common.navigator,
      whiteHeader: (state) => state.header.whiteHeader
    })
  },
  watch: {
    '$route'(val) {
      this.$store.dispatch('common/getNavigator', { type: 'product_menu', id: val.query.menu_id, level: 3 })
      this.handleProductData(val.query.menu_id)
    }
  },
  async mounted() {
    this.$store.commit('header/SET_WHITE_HEADER', false)
    // 获取浏览器滚轮
    window.addEventListener('scroll', () => {
      this.subnavTop = document.documentElement.scrollTop || document.body.scrollTop || window.pageYOffset
      // 鼠标向上滚动，二级导航置顶，直到滚动到初始位置
      var scroll = document.documentElement.scrollTop - this.i
      this.i = document.documentElement.scrollTop
      scroll < 0 ? this.subnavUp = true : this.subnavUp = false
    })
    await this.$store.dispatch('common/getNavigator', { type: 'product_menu', id: this.$route.query.menu_id, level: 3 })
    this.handleProductData(this.$route.query.menu_id)
  },
  methods: {
    // 获取接口数据
    handleProductData(menu_id) {
      productCategoryApi({
        menu_id
      }).then((res) => {
        this.$store.commit('common/SET_MATE', res.data.seo)
        this.dataInfo = res.data.product_menu
        if (res.data.product_menu && res.data.product_menu.download) {
          this.dataInfoDownload = res.data.product_menu.download.map(item => ({
            ...item,
            title: item.menu_name
          }))
        }
        this.tabsList = res.data.top_nav_arr.map(item => ({
          ...item,
          title: item.name,
          anchor: item.product_modular
        }))
      }).catch((err) => {
        console.log(err)
      })
    },
    // 二级导航定位
    handleScroll(anchor) {
      const node = document.querySelector(`#${anchor}`)
      const offsetTop = calculateTop(node)
      scrollToTop(offsetTop - 90)
    }
  }
}
</script>

<style lang="less" scoped>
.products-wrap {
  padding-bottom: 72px;
  @media screen and (max-width: 768px) {
    padding-bottom: 32px;
  }
}
</style>
<style lang="less">
  @import '~@/styles/common/common.less';
.products-wrap {
  .detail-subnav {
    border-bottom: 1px solid #ddd;
    padding-bottom: 16px;
    .subnav-box {
      display: flex;
      justify-content: space-between;
      .left-title {
        font-size: 18px;
        font-weight: bold;
        color: #232931;
        line-height: 24px;
      }
      .right-list {
        display: flex;
        .list-item {
          cursor: pointer;
          margin-left: 24px;
          font-size: 14px;
          color: #333333;
          line-height: 19px;
          .transition;
          &:hover {
            color: @theme;
          }
        }
      }
    }
  }
  .subnav-fixed {
    position: fixed;
    width: 100%;
    top: 79px;
    background: #fff;
    padding: 24px 0 16px 0;
    border-top: 1px solid #ddd;
    z-index: 1000;
  }
  .subnav-top {
    top: 0 ;
  }
  @media screen and (max-width: 768px) {
    .detail-subnav {
      padding-bottom: 0;
      .subnav-box {
        display: block;
        .left-title {
          text-align: center;
          margin: 18px 0 10px;
        }
        .right-list {
          overflow-x: auto;
          padding-bottom: 16px;
          .list-item {
            white-space: nowrap;
          }
        }
      }
    }
    .subnav-fixed {
      top: 93px;
      border-top: none;
      padding: 0;
    }
  }
}
</style>
