import { fetch } from '@/plugins/axios.js'
import lang from '@/utils/lang'
import site from '@/utils/site'

const apiUrl = {
  productList: `/product/lists`, // 产品页
  productCategory: `/product/menu`, // 产品类别页
  productSelector: `/product/product_select`, // 产品对比选择页
  productCompare: `/product/product_compare`, // 产品对比结果页
  productDetail: `/product/detail` // 产品详情页
}

// 产品页
export const productListApi = () => fetch(`${apiUrl.productList}/${lang}/${site}`)
// 产品类别页
export const productCategoryApi = (params) => fetch(`${apiUrl.productCategory}/${lang}/${site}`, params)
// 产品对比选择页
export const productSelectorApi = (params) => fetch(`${apiUrl.productSelector}/${lang}/${site}`, params, 'post')
// 产品对比结果页
export const productCompareApi = (params) => fetch(`${apiUrl.productCompare}/${lang}/${site}`, params, 'post')
// 产品详情页
export const productDetailApi = (params) => fetch(`${apiUrl.productDetail}/${lang}/${site}`, params)
