<template>
  <div class="products-banner">
    <!-- banner -->
    <div class="banner-box">
      <swiper ref="tabSwiper" class="banner-swiper" :options="bannerSwiperOption">
        <swiper-slide v-for="(item, index) in banner" :key="index">
          <div class="banner-img show-banner-pc" :style="`background: url(${item.image}) center center / cover no-repeat`" @click="handleBannerLink(item)">
            <div class="text-box">
              <div class="container text-content">
                <h1 class="title" :style="`color: ${item.color}`">{{ item.title }}</h1>
                <h5 class="subtitle" :style="`color: ${item.color}`">{{ item.description }}</h5>
              </div>
            </div>
          </div>
          <div class="banner-img show-banner-mobile" :style="`background: url(${item.mobile_image}) center center / cover no-repeat`" @click="handleBannerLink(item)">
          </div>
        </swiper-slide>
      </swiper>
      <div class="products-pagination"></div>
      <div slot="button-prev" class="products-button-prev">
        <span class="iconfont icon-a-zuojiantou1x"></span>
      </div>
      <div slot="button-next" class="products-button-next">
        <span class="iconfont icon-a-youjiantou1x"></span>
      </div>
    </div>
  </div>
</template>

<script>
import 'swiper/dist/css/swiper.min.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import { mapState } from 'vuex'
export default {
  name: 'Products',
  components: {
    swiper,
    swiperSlide
  },
  props: {
    id: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      bannerSwiperOption: { // banner轮播配置
        autoplay: false,
        loop: false,
        slidesPerView: 1, // 每屏显示个数
        allowTouchMove: true, // 是否可拖动
        pagination: {
          el: '.products-pagination',
          clickable: true
        },
        navigation: { // 前进后退按钮
          nextEl: '.products-button-next',
          prevEl: '.products-button-prev'
        }
      }
    }
  },
  computed: {
    ...mapState({
      banner: state => state.common.banner
    })
  },
  watch: {
    id(val) {
      this.$store.dispatch('common/getBanner', { menu_id: val })
    },
    immediate: true,
    deep: true
  },
  methods: {
    // banner跳转
    handleBannerLink() {}
  }
}
</script>

<style lang="less" scoped>
.products-banner {
  .banner-box {
    width: 100%;
    height: 558px;
    position: relative;
    .banner-swiper {
      cursor: pointer;
      .banner-img {
        height: 558px;
        .text-box {
          width: 100%;
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          .text-content {
            .title {
              font-size: 48px;
              color: #fff;
              font-weight: bold;
              line-height: 48px;
            }
            .subtitle {
              font-size: 18px;
              font-weight: 400;
              line-height: 26px;
              color: #fff;
              margin-top: 24px;
            }
          }
        }
      }
      .show-banner-mobile {
        display: none;
      }
    }
    .products-pagination {
      position: absolute;
      bottom: 24px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 9;
      /deep/ .swiper-pagination-bullet {
        width: 12px;
        height: 12px;
        cursor: pointer;
        margin-left: 24px;
        background: transparent;
        border: 1px solid #979797;
        opacity: 1;
      }
      /deep/ .swiper-pagination-bullet-active {
        background: #FFFFFF;
      }
    }
    .products-button-prev {
      left: 48px;
    }
    .products-button-next {
      right: 48px;
    }
    .products-button-prev,
    .products-button-next {
      position: absolute;
      top: 50%;
      z-index: 10;
      cursor: pointer;
      .iconfont {
        color: #fff;
        font-size: 48px;
      }
    }
    .swiper-button-disabled {
      .iconfont {
        color: rgba(255, 255, 255, 0.7);
      }
    }
  }
  @media screen and (max-width: 1600px) {
    .banner-box {
      .banner-swiper {
        .banner-img {
          .text-box {
            .text-content {
              max-width: 1150px;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 1400px) {
    .banner-box {
      .banner-swiper {
        .banner-img {
          .text-box {
            .text-content {
              width: 76%;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 1440px) {
    .banner-box {
      .swiper-button-prev {
        left: 10px;
      }
      .swiper-button-next {
        right: 10px;
      }
    }
  }
  @media screen and (max-width: 1024px) {
    .banner-box {
      height: 480px;
      .banner-swiper {
        .banner-img {
          height: 480px;
          .text-box {
            padding: 0 48px;
            .text-content {
              .title {
                font-size: 38px;
                line-height: 54px;
              }
              .subtitle {
                font-size: 20px;
              }
            }
          }
        }
      }
    }
    .swiper-button-next,
    .swiper-button-prev {
      height: 30px;
    }
  }
  @media screen and (max-width: 998px) {
    .banner-box {
      .banner-swiper {
        .banner-img {
          .text-box {
            .text-content {
              width: 72%;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 768px) {
    margin-top: 93px;
    .banner-box  {
      max-height: 240px;
      .banner-swiper {
        .banner-img {
          max-height: 240px;
          .text-box {
            text-align: center;
            top: 87px;
            .text-content {
              width: 92%;
              .title {
                font-size: 32px;
                line-height: 32px;
              }
              .subtitle {
                font-size: 16px;
                line-height: 20px;
              }
            }
          }
        }
      }
      .products-pagination {
        left: 45%;
        transform: translateX(-45%);
        bottom: 10px;
      }
      .products-button-prev,
      .products-button-next {
        display: none;
      }
    }
  }
}
</style>
