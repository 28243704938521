<template>
  <div class="product-sidebar-mobile">
    <div class="sidebar-box">
      <div class="iconfont icon-shanchu_1" @click="handleClose"></div>
      <div class="content-box">
        <div class="sidebar-reset-btn">
          <a class="btn-box" @click="handleNavReset">{{ LP.lang_reset }}</a>
        </div>
        <!-- 一级菜单 -->
        <div
          v-for="(item, index) in sidebarList"
          :key="index"
          class="sidebar-item"
          :class="{active: currentIndex === index}"
        >
          <div
            class="text-box"
            @click="handleLevelMenu(index)"
          >
            <div class="text-left">
              <span class="title">{{ item.search_name }}</span>
              <span v-if="item.children && item.children.length" class="number">({{ item.children.length }})</span>
            </div>
            <div class="icon-right">
              <i
                class="iconfont"
                :class="currentIndex === index ? 'icon-a-icon-expand1x' : 'icon-a-icon-close1x'"
              ></i>
            </div>
          </div>
          <!-- 二级菜单 -->
          <el-collapse-transition>
            <ul v-if="item.children.length && currentIndex === index" class="child-list">
              <el-checkbox
                v-model="checkAll[index]"
                :indeterminate="isIndeterminate[index]"
                @change="handleCheckAllChange"
              >
                {{ LP.lang_all }}
              </el-checkbox>
              <el-checkbox-group v-model="checked[index]" @change="handleCheckedChange">
                <el-checkbox
                  v-for="(itemChild, indexChild) in item.children"
                  :key="indexChild"
                  :label="itemChild.search_id"
                >
                  {{ itemChild.search_name }}
                </el-checkbox>
              </el-checkbox-group>
            </ul>
          </el-collapse-transition>
        </div>
      </div>
      <div class="submit-btn" @click="handleClose">
        <span class="title">{{ LP.lang_submit }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    // 侧边栏数据
    sidebarList: {
      type: Array,
      default: () => []
    },
    // 一级菜单当前展开
    currentIndex: {
      type: [Number],
      default: -1
    },
    // 侧边栏全选
    checkAll: {
      type: Array,
      default: () => []
    },
    // 侧边栏每一项选择
    checked: {
      type: Array,
      default: () => []
    },
    // 选中一部分的横线
    isIndeterminate: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    // 清空侧边栏筛选
    handleNavReset() {
      this.$emit('handleNavReset')
    },
    // 一级菜单展开折叠
    handleLevelMenu(index) {
      this.$emit('handleLevelMenu', index)
    },
    // 侧边栏全选筛选
    handleCheckAllChange(val) {
      this.$emit('handleCheckAllChange', val)
    },
    // 侧边栏单项筛选
    handleCheckedChange(value) {
      this.$emit('handleCheckedChange', value)
    },
    // 关闭弹窗
    handleClose() {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/styles/common/common.less';
.product-sidebar-mobile {
  width: 100%;
  height: 100%;
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1002;
  .sidebar-box {
    padding: 20px;
    .iconfont {
      font-size: 24px;
      color: #909399;
      text-align: right;
    }
    // 侧边栏
    .content-box {
      .sidebar-reset-btn {
        padding: 16px 0 10px 0;
        border-bottom: 1px solid #DDDDDD;
        text-align: right;
        .btn-box {
          display: inline-block;
          font-size: 14px;
          font-weight: 600;
          line-height: 16px;
          padding: 4px 15px;
          border-radius: 16px;
          border: 1px solid #CCCCCC;
          cursor: pointer;
          .transition();
          &:hover {
            color: #fff;
            background-color: @theme;
            border: 1px solid @theme;
          }
        }
      }
      .sidebar-item {
        cursor: pointer;
        .text-box {
          display: flex;
          justify-content: space-between;
          padding: 12px 0;
          border-bottom: 1px solid #DDDDDD;
          .text-left {
            .title {
              font-size: 15px;
              font-weight: 600;
              color: #333333;
              line-height: 16px;
            }
            .number {
              font-size: 14px;
              color: #666666;
              line-height: 16px;
              margin-left: 10px;
            }
          }
          .icon-right {
            &>.iconfont {
              font-size: 16px;
            }
          }
        }
        .child-list {
          padding: 24px 16px;
          /deep/ .el-checkbox {
            width: 100%;
            font-size: 14px;
            color: #666666;
            line-height: 16px;
          }
          /deep/ .el-checkbox + .el-checkbox {
            margin-top: 16px;
          }
          /deep/ .el-checkbox-group {
            margin-top: 16px;
          }
        }
        .active {
          background: #ccc;
        }
      }
    }
    .submit-btn {
      position: fixed;
      width: 100%;
      bottom: 0;
      left: 0;
      background: #232931;
      padding: 10px;
      text-align: center;
      .title {
        display: block;
        font-size: 16px;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 21px;
      }
    }
  }
}
</style>
