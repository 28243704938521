<template>
  <div v-if="data && data.length" class="product-categories">
    <public-title :data-info="titleInfo" />
    <ul
      class="list-box container"
      :class="{ 'list-center': data && data.length <= 2 }"
    >
      <li
        v-for="(item, index) in data"
        :key="index"
        class="items"
        @click="clickHandle(item)"
      >
        <div class="img-box">
          <img :src="item.menu_image" alt="" />
        </div>
        <div class="text-box">
          <h5 class="title">{{ item.menu_name }}</h5>
          <p class="desc">{{ item.description }}</p>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import PublicTitle from '@/components/public/PublicTitle.vue'
export default {
  components: { PublicTitle },
  props: {
    data: {
      type: [Array, Object],
      default: () => []
    }
  },
  data() {
    return {
      titleInfo: {
        title: this.LP.lang_product_categories
      }
    }
  },
  methods: {
    // 跳转
    clickHandle(item) {
      this.$router.push({
        path: `/${this.$route.params.site}/${this.$route.params.lang}/products/productSelector`,
        query: {
          menu_id: item.menu_id
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/styles/common/common.less';
.product-categories {
  .list-box {
    border-radius: 12px;
    display: flex;
    flex-wrap: wrap;
    &.list-center {
      justify-content: center;
    }
    .items {
      cursor: pointer;
      width: calc((100% - 40px) / 3);
      background: #F5F5F5;
      margin-right: 16px;
      margin-bottom: 16px;
      display: flex;
      border-radius: 12px;
      &:nth-child(3n) {
        margin-right: 0;
      }
      .img-box {
        width: 32%;
        // min-width: 200px;
        overflow: hidden;
        align-self: center;
        img {
          width: 100%;
          height: 100%;
          .transition;
        }
      }
      .text-box {
        width: 68%;
        padding: 24px 16px 28px 17px;
        .title {
          font-size: 18px;
          font-weight: 600;
          color: #232931;
          line-height: 18px;
        }
        .desc {
          font-size: 14px;
          color: #666666;
          line-height: 19px;
          margin-top: 16px;
        }
      }
      &:hover {
        .img-box {
          img {
            .scale;
          }
        }
      }
    }
  }
  @media screen and (max-width: 1024px) {
    .list-box {
      .items {
        width: calc((100% - 20px) / 2);
        &:nth-child(3n) {
          margin-right: 16px;
        }
        &:nth-child(2n) {
          margin-right: 0;
        }
      }
    }
  }
   @media screen and (max-width: 768px) {
    .list-box {
      display: block;
      .items {
        width: 100%;
        margin-right: 0;
        margin-bottom: 10px;
        &:last-child {
          margin-bottom: 10px;
        }
        .img-box {
          width: 30.5%;
          min-width: 107px;
        }
        .text-box {
          width: 69.5%;
          padding: 8px 12px;
          .title {
            font-size: 16px;
            line-height: 16px;
          }
        }
      }
    }
  }
}
</style>
