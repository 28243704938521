<template>
  <div v-if="data && data.length" class="why-choose-us">
    <public-title :data-info="titleInfo" />
    <div class="text-box container">
      {{ data }}
    </div>
  </div>
</template>

<script>
import PublicTitle from '@/components/public/PublicTitle.vue'
export default {
  components: {
    PublicTitle
  },
  props: {
    data: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      titleInfo: {
        title: this.LP.lang_why_choose_us
      }
    }
  }
}
</script>

<style lang="less" scoped>
.why-choose-us {
  .text-box {
    font-size: 14px;
    color: #666666;
    line-height: 19px;
    margin-top: 16px;
  }
  @media screen and (max-width: 768px) {
    /deep/ .public-title {
      .text-box {
        padding: 40px 0 15px;
      }
    }
  }
}
</style>
