<template>
  <div class="products-detail">
    <public-bread-crumb :bread-crumb="navigator" />
    <!-- 二级导航 -->
    <div class="products-subnav">
      <div class="subnav-box container">
        <span class="subnav-title">{{ dataInfo.product_name }}</span>
      </div>
    </div>
    <detail-text :data-info="dataInfo" />
    <div v-if="dataInfo" class="detail-tab-box">
      <detail-tab :data-info="dataInfo" />
    </div>
  </div>
</template>

<script>
import PublicBreadCrumb from '@/components/public/PublicBreadCrumb.vue'
import DetailText from '@/components/products/productDetail/DetailText.vue'
import DetailTab from '@/components/products/productDetail/DetailTab.vue'
import { productDetailApi } from '@/api/products'
import { mapState } from 'vuex'
export default {
  components: {
    PublicBreadCrumb,
    DetailText,
    DetailTab
  },
  data() {
    return {
      breadCrumb: [],
      dataInfo: {}
    }
  },
  computed: {
    ...mapState({
      navigator: state => state.common.navigator,
      whiteHeader: (state) => state.header.whiteHeader
    })
  },
  async mounted() {
    this.$store.commit('header/SET_WHITE_HEADER', true)
    await this.$store.dispatch('common/getNavigator', { type: 'product_menu', id: this.$route.query.product_id, level: 5 })
    this.handleData()
  },
  methods: {
    // 获取接口数据
    handleData() {
      productDetailApi({
        product_id: this.$route.query.product_id
      }).then((res) => {
        this.$store.commit('common/SET_MATE', res.data.seo)
        this.dataInfo = res.data.product
      }).catch((err) => {
        console.log(err)
      })
    },
    // tab切换
    handleSwitch() {

    }
  }
}
</script>

<style lang="less" scoped>
.products-detail {
  margin-top: 80px;
  border-top: 1px solid #ddd;
  .products-subnav {
    border-bottom: 1px solid #DDDDDD;
    padding: 9px 0 16px 0;
    background: #fff;
    .subnav-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .subnav-title {
        font-size: 18px;
        font-weight: bold;
        color: #232931;
        line-height: 24px;
      }
    }
  }
  .detail-tab-box {
    border-top: 1px solid #ddd;
  }
  @media screen and (max-width: 768px) {
    margin-top: 93px;
    .products-subnav {
      display: none;
    }
  }
}
</style>
