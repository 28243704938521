<template>
  <div class="products-detail-text">
    <div class="detail-content container">
      <div class="detail-swiper-box">
        <!-- 大图 -->
        <swiper ref="swiperTop" :options="swiperOptionTop" class="gallery-top">
          <swiper-slide v-for="(item, index) in dataInfo.image_list" :key="index" class="big-img-list">
            <img :src="item.image_thumb" alt="" />
          </swiper-slide>
        </swiper>
        <!-- 缩略图 -->
        <div class="small-img-box">
          <swiper ref="swiperThumbs" :options="swiperOptionThumbs" class="gallery-thumbs">
            <swiper-slide
              v-for="(item, index) in dataInfo.image_list"
              :key="index"
              class="small-img-list"
              :class="{active: index === activeIndex}"
              @click.native="handleThumbs(index)"
            >
              <img :src="item.image_thumb" alt="" />
            </swiper-slide>
          </swiper>
          <div slot="button-prev" class="swiper-button-prev">
            <i class="iconfont icon-a-zuojiantou1x"></i>
          </div>
          <div slot="button-next" class="swiper-button-next">
            <i class="iconfont icon-a-youjiantou1x"></i>
          </div>
        </div>
      </div>
      <div class="detail-text">
        <h4 class="title">{{ dataInfo.product_name }}</h4>
        <h5 class="subtitle" v-html="dataInfo.product_description"></h5>
        <p class="desc" v-html="dataInfo.product_content"></p>
      </div>
    </div>
  </div>
</template>

<script>
import 'swiper/dist/css/swiper.min.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
export default {
  components: {
    swiper,
    swiperSlide
  },
  props: {
    dataInfo: {
      type: [Object, Array],
      default: () => []
    }
  },
  data() {
    return {
      activeIndex: 0, // 当前点击缩略图索引值
      swiperOptionTop: {
        spaceBetween: 10, // 间距
        allowTouchMove: false // 禁止手动拖动
      },
      swiperOptionThumbs: {
        spaceBetween: 10, // 间距
        slidesPerView: 3, // 一屏展示个数
        touchRatio: 0.2, // 触摸速度
        navigation: { // 切换
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        breakpoints: {
          768: {
            spaceBetween: 0
          }
        }
      }
    }
  },
  methods: {
    // 切换图片
    handleThumbs(i) {
      this.activeIndex = i
      this.$refs.swiperTop.swiper.slideTo(i)
    }
  }
}
</script>

<style lang="less" scoped>
.products-detail-text {
  margin-bottom: 64px;
  .detail-content {
    display: flex;
    justify-content: space-between;
    .detail-swiper-box {
      width: 37%;
      height: 500px;
      position: relative;
      .gallery-top {
        height: 80%!important;
        width: 100%;
        max-width: 400px;
        .big-img-list {
          img {
            width: 100%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }
      .small-img-box {
        height: 20%!important;
        position: relative;
        max-width: 400px;
        .gallery-thumbs {
          height: 100%;
          box-sizing: border-box;
          padding: 10px 0;
          border: 1px solid #DDDDDD;
          .small-img-list {
            opacity: 0.4;
            cursor: pointer;
            img {
              max-width: 56px;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
            &.active {
              opacity: 1;
            }
          }
          .swiper-slide {
            width: 25%;
            height: 100%;
            opacity: 0.4;
          }
        }
        /deep/ .swiper-button-next,
        .swiper-button-prev {
          background-image: none;
          width: 20px;
          margin-top: -10px;
          &>.iconfont {
            font-size: 20px;
          }
        }
        /deep/ .swiper-button-prev {
          left: -30px;
        }
        /deep/ .swiper-button-next {
          right: -30px;
        }
      }
    }
    .detail-text {
      width: 53%;
      margin: 0 10%;
      padding: 24px 0 75px 0;
      .title {
        font-size: 36px;
        font-weight: bold;
        color: #333333;
        line-height: 48px;
      }
      .subtitle {
        font-size: 24px;
        color: #333333;
        line-height: 32px;
        margin-top: 16px;
      }
      .desc {
        font-size: 14px;
        color: #606266;
        line-height: 19px;
        margin-top: 16px;
        display: block;
        /deep/ img {
          max-width: 100%;
        }
      }
    }
  }
  .swiper-slide {
    background-size: cover;
    background-position: center;
  }
  @media screen and (max-width: 768px) {
    margin-bottom: 20px;
    .detail-content {
      display: block;
      .detail-swiper-box  {
        width: 100%;
        height: 416px;
        .small-img-box  {
          .gallery-thumbs {
            width: 80%;
            .swiper-slide {
              width: 33% !important;
            }
          }
          /deep/ .swiper-button-prev {
            left: 8px;
          }
          /deep/ .swiper-button-next {
            right: 8px;
          }
        }
      }
      .detail-text {
        width: 100%;
        margin: 0;
        padding: 16px 0 0;
         .title {
           font-size: 20px;
           line-height: 27px;
         }
         .subtitle {
           font-size: 15px;
           line-height: 20px;
         }
         .desc {
           font-size: 12px;
           line-height: 16px;
         }
      }
    }
  }
}
</style>
