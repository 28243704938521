<template>
  <el-dialog
    :visible.sync="downloadVisible"
    width="100%"
    :before-close="handleClose"
    custom-class="download-dialog"
    center
  >
    <div class="text-box">
      <p>{{ LP.lang_please_login_first }}</p>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="goLogin">{{ LP.lang_login }}</el-button>
      <el-button type="info" @click="goSignUp">{{ LP.lang_sign_up }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  props: {
    // 是否展示弹窗
    downloadVisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
    }
  },
  methods: {
    // 取消
    handleClose() {
      this.$emit('close')
    },
    // 去登录
    goLogin() {
      window.open(`https://www.dahuasecurity.com/${this.$route.params.lang}/user/login`, '_self')
    },
    // 去注册
    goSignUp() {
      window.open(`https://www.dahuasecurity.com/${this.$route.params.lang}/user/signup`, '_self')
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .download-dialog {
  max-width: 524px;
  border-radius: 10px;
  .el-dialog__headerbtn {
    top: -42px;
    right: -42px;
  }
  .el-dialog__header {
    padding: 0;
  }
  .el-dialog__body {
    padding: 48px 20px 38px 20px;
    text-align: center;
    font-size: 16px;
  }
  .el-dialog__headerbtn {
    font-size: 32px;
    background: url('../../assets/img/close.png') no-repeat;
  }
  .el-dialog__headerbtn .el-dialog__close {
    color: transparent;
  }
  .el-button {
    display: flex;
    margin: 0 auto;
    min-width: 120px;
    justify-content: center;
  }
  .el-button + .el-button {
    margin-top: 16px;
  }
  .el-dialog__footer {
    padding: 10px 20px 48px;
  }
}
</style>
