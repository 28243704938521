<template>
  <div class="products-wrap">
    <public-banner :banner-info="bannerInfo" />
    <public-bread-crumb :bread-crumb="navigator" />
    <public-title :data-info="productsData" site="left" />
    <div class="products-detail container">
      <div class="detail-des">
        {{ productsData.desc }}
      </div>
      <ul v-if="detailList && detailList.length" class="detail-list">
        <li
          v-for="(item, index) in detailList"
          :key="index"
          class="detail-item"
          :class="{'low': detailList.length < 3}"
          @click="linkHandle(item)"
        >
          <div class="img-box">
            <div class="detail-img" :style="`background: url(${item.menu_image}) center center / cover no-repeat`">
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import PublicBanner from '@/components/public/PublicBanner.vue'
import PublicBreadCrumb from '@/components/public/PublicBreadCrumb.vue'
import PublicTitle from '@/components/public/PublicTitle.vue'
import { mapState } from 'vuex'
import { productListApi } from '@/api/products'

export default {
  name: 'Products',
  components: {
    PublicBanner,
    PublicBreadCrumb,
    PublicTitle
  },
  data() {
    return {
      bannerInfo: {},
      productsData: {
        title: '',
        desc: ''
      },
      detailList: []
    }
  },
  computed: {
    ...mapState({
      banner: state => state.common.banner,
      navigator: state => state.common.navigator,
      whiteHeader: (state) => state.header.whiteHeader
    })
  },
  created() {
    this.$store.commit('header/SET_WHITE_HEADER', false)
    this.getData()
  },
  methods: {
    // 获取页面以及banner 面包屑数据
    async getData() {
      if (this.$route.fullPath.includes('business')) {
        await this.$store.dispatch('common/getBanner', { banner_id: 234 })
        this.bannerInfo = this.banner[0]
      } else {
        await this.$store.dispatch('common/getBanner', { banner_id: 235 })
        this.bannerInfo = this.banner[0]
      }
      await productListApi().then(res => {
        ({ nav_name: this.productsData.title, nav_description: this.productsData.desc } = res.data.nav_info)
        this.detailList = res.data.product_menu
        this.$store.commit('common/SET_MATE', res.data.seo)
      })
      await this.$store.dispatch('common/getNavigator', { type: 'product_menu', id: 1, level: 2 })
    },
    // 点击跳转
    linkHandle(item) {
      // 跳转外链
      if (item.menu_keywords) {
        window.open(item.menu_keywords)
      } else {
        this.$router.push({
          path: `/${this.$route.params.site}/${this.$route.params.lang}/products/productCategories`,
          query: {
            menu_id: item.menu_id
          }
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/styles/common/common.less';
.products-wrap {
  /deep/ .public-bread-crumb {
    padding: 16px 0 24px;
    .header-item {
      margin-left: 11px;
    }
  }
  /deep/ .public-title {
    border-bottom: 1px solid #ddd;
    .text-box {
      padding: 0 0 16px;
      .title {
        font-size: 24px;
      }
      .desc {
        display: none;
      }
    }
  }
  .products-detail {
    padding: 71px 0 72px;
    .detail-des {
      margin-bottom: 48px;
      font-size: 14px;
      color: #666666;
      line-height: 19px;
    }
    .detail-list {
      display: flex;
      height: 100%;
      flex-wrap: wrap;
      .detail-item {
        cursor: pointer;
        width: 32%;
        margin-right: 17px;
        margin-bottom: 15px;
        overflow: hidden;
        &:nth-child(3n) {
          margin-right: 0;
        }
        &:nth-child(n+3) {
          margin-bottom: 0;
        }
        .img-box {
          overflow: hidden;
          border-radius: 12px;
          .detail-img {
            width: 100%;
            height: 400px;
            .transition;
          }
        }
        &.low {
          flex: 1;
        }
        &:hover {
          .img-box {
            .detail-img {
              .scale;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 1024px) {
    .products-detail {
      .detail-list {
        .detail-item {
          width: 48%;
          &:nth-child(3n) {
            margin-right: 17px;
          }
          &:nth-child(n+3) {
            margin-bottom: 15px;
          }
          &:nth-child(2n) {
            margin-right: 0;
          }
          &:last-child {
            margin-bottom: 0;
          }
          .img-box {
            .detail-img {
              height: 350px;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 768px) {
    /deep/ .public-title {
      border-bottom: none;
      .text-box {
        padding: 16px 0 ;
        .title {
          text-align: center;
        }
        .desc {
          display: block;
        }
      }
    }
    .products-detail {
      padding: 40px 0;
       .detail-des {
         display: none;
       }
      .detail-list {
        .detail-item {
          width: 100%;
          margin-right: 0;
          .img-box {
            .detail-img {
              height: 240px;
            }
          }
        }
      }
    }
  }
}
</style>

