<template>
  <div class="products-detail-tab">
    <public-tabs :data-info="tabsList" :default-index="defaultIndex" @handleTab="handleSwitch" />
    <!-- Overview -->
    <div v-if="tabActive === 0" class="overview-box">
      <!-- 图片形式 -->
      <div v-if="!dataInfo.overview" class="overview-img">
        <img class="img-box" :src="dataInfo.long_image" alt="" />
      </div>
      <!-- 单页形式 -->
      <div class="overview-text" v-html="dataInfo.overview"></div>
    </div>
    <!-- Specifications -->
    <div v-if="tabActive === 1" class="detail-tab-list specifications-box container clearfix">
      <!-- <div class="tab-left">
        <product-selector />
      </div> -->
      <div class="tab-right">
        <div class="table-content" v-html="dataInfo.product_technical"></div>
      </div>
    </div>
    <!-- Accessories -->
    <div v-if="tabActive === 2" class="detail-tab-list accessories-box container clearfix">
      <!-- <div class="tab-left">
        <accessory-selector />
      </div> -->
      <div class="tab-right">
        <ul class="accessories-list">
          <li v-for="(item, index) in dataInfo.accessories" :key="index" class="accessories-items">
            <router-link to="">
              <div class="product-img">
                <img :src="item.product_thumb" alt="" class="img-box" />
              </div>
              <div class="product-info">
                <div class="title-box">
                  <span class="title">{{ item.product_name }}</span>
                  <i class="iconfont icon-a-youjiantou1x"></i>
                </div>
                <div class="desc">{{ item.product_description }}</div>
              </div>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
    <!-- Download -->
    <div v-if="tabActive === 3" class="detail-tab-list download-box container">
      <ul class="download-tab">
        <template v-for="(item, index) in LP.lang_arr6">
          <li
            v-if="item"
            :key="index"
            ref="list"
            class="download-items"
            :class="{active: downloadIndex === index + 1}"
            @click="handleDownLoadTab(item, index)"
          >
            <span class="title">{{ item }}</span>
          </li>
        </template>
      </ul>
      <div class="download-table-box">
        <table>
          <thead>
            <template>
              <tr>
                <th>{{ LP.lang_class }}</th>
                <th>{{ LP.lang_date }}</th>
                <th>{{ LP.lang_title }}</th>
                <th>{{ LP.lang_language }}</th>
                <th>{{ LP.lang_type }}</th>
                <th>{{ LP.lang_download }}</th>
              </tr>
            </template>
          </thead>
          <tbody>
            <template v-for="(item, index) in dataInfo.download">
              <template v-if="index == downloadIndex">
                <tr
                  v-for="(subitem, subindex) in item"
                  :key="subindex"
                >
                  <td>{{ LP.lang_arr6[index - 1] }}</td>
                  <td>{{ subitem.post_date }}</td>
                  <td>{{ subitem.download_name }}</td>
                  <td>{{ subitem.download_language }}</td>
                  <td>{{ subitem.download_type }}</td>
                  <td>
                    <a
                      :href="subitem.download_download"
                      target="_blank"
                      rel="nofollow noopener noreferrer"
                      class="download-icon"
                    >
                      <span v-if="subitem.download_type === 'pdf'" class="iconfont icon-pdf"></span>
                      <span v-else-if="subitem.download_type === 'jpg'" class="iconfont icon-jpg"></span>
                      <span v-else-if="subitem.download_type === 'doc'" class="iconfont icon-doc"></span>
                      <span v-else-if="subitem.download_type === 'docx'" class="iconfont icon-docx"></span>
                      <span v-else class="iconfont icon-png"></span>
                    </a>
                  </td>
                </tr>
              </template>
            </template>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import PublicTabs from '@/components/public/PublicTabs.vue'
// import AccessorySelector from './AccessorySelector.vue'
// import ProductSelector from './productSelector.vue'
export default {
  components: {
    PublicTabs
    // AccessorySelector,
    // ProductSelector
  },
  props: {
    dataInfo: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      tabActive: 0, // 当前点击tab,只做内容展示
      defaultIndex: 0, // 菜单切换
      tabsList: [ // tab菜单
        {
          title: this.LP.lang_overview
        },
        {
          title: this.LP.lang_specifications
        },
        {
          title: this.LP.lang_accessories
        },
        {
          title: this.LP.lang_download
        }
      ],
      jump: false, // 鼠标悬浮显示跳转
      downloadList: [], // 下载tab一级菜单
      downloadIndex: 1 // download当前选中tab
    }
  },
  watch: {
    dataInfo(val) {
      if (!val.long_image && !val.overview) {
        this.tabsList.shift()
        this.tabActive = 1
      } else {
        this.tabActive = 0
      }
    },
    immediate: true,
    deep: true
  },
  methods: {
    // tab切换
    handleSwitch(item, index) {
      if (!this.dataInfo.long_image && !this.dataInfo.overview) {
        this.tabActive = index + 1
      } else {
        this.tabActive = index
      }
      this.defaultIndex = index
    },
    // download tab切换
    handleDownLoadTab(item, index) {
      this.downloadIndex = index + 1
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/styles/common/common';
.products-detail-tab {
  min-height: 640px;
  .overview-box {
    .overview-img {
      text-align: center;
      .img-box {
        max-width: 100%;
      }
    }
  }
  .detail-tab-list {
    padding: 30px 0;
    .tab-right {
      // width: calc(100% - 286px);
      width: 100%;
      margin-right: 20px;
      float: left;
      /deep/ .table-content {
        font-size: 14px;
        tr {
          td {
            padding: 6px 0px 6px 5px !important;
          }
        }
      }
    }
    .tab-left {
      width: 266px;
      float: right;
    }
  }
  .accessories-box {
    .tab-right {
      .accessories-list {
        display: flex;
        flex-wrap: wrap;
        .accessories-items {
          width: calc((100% - 32px) / 3);
          position: relative;
          border: 1px solid #dadada;
          background-color: #fff;
          margin: 0 16px 16px 0;
          .transition();
          &:nth-child(3n) {
            margin-right: 0;
          }
          .product-img {
            padding: 0 26px;
            overflow: hidden;
            .img-box {
              max-width: 100%;
              .transition();
            }
          }
          .product-info {
            padding: 8px 16px;
            border-top: 1px solid #E4E7ED;
            .title-box {
              display: flex;
              justify-content: space-between;
              align-items: center;
              .title {
                font-size: 18px;
                color: #333333;
                line-height: 24px;
              }
              &>.iconfont {
                font-size: 18px;
              }
            }
            .desc {
              font-size: 14px;
              color: #666666;
              line-height: 19px;
              margin-top: 8px;
            }
          }
          &:hover {
            .shadow();
            .product-img {
              .img-box {
                .scale();
              }
            }
            .product-info {
              .title-box {
                .title {
                  color: @theme;
                }
              }
            }
          }
        }
      }
    }
  }
  .download-box {
    .download-tab {
      overflow: hidden;
      background-color: #fff;
      border: 1px solid #bfcbd9;
      position: relative;
      margin-bottom: 20px;
      .download-items {
        min-width: 130px;
        height: 45px;
        line-height: 45px;
        text-align: center;
        float: left;
        cursor: pointer;
        border-right: 1px solid #bfcbd9;
        border-bottom: 1px solid #bfcbd9;
        position: relative;
        z-index: 1;
        margin-bottom: -1px;
        padding: 0 10px;
        .title {
          font-size: 14px;
        }
        &:hover {
          background-color: @theme;
          .title {
            color: #fff;
          }
        }
      }
      .active {
        background-color: @theme;
        .title {
          color: #fff;
        }
      }
    }
    .download-table-box {
      font-size: 14px;
      table {
        width: 100%;
      }
      th {
        text-align: center;
      }
      th,
      td {
        padding: 12px 42px;
        word-break: break-word;
        text-align: center;
        .download-icon {
          display: block;
          text-align: center;
          .iconfont {
            font-size: 18px;
            cursor: pointer;
          }
        }
      }
      thead tr {
        background-color: #dadada;
        white-space: nowrap;
      }
      tbody tr:nth-child(2n) {
        background-color: #f5f5f5;
      }
    }
  }
  .public-tabs {
    margin-bottom: 0;
  }
  @media screen and (max-width: 768px) {
    .detail-tab-list {
      padding: 18px 0;
      .tab-right {
        width: 100%;
        margin-right: 0;
      }
      .tab-left {
        width: 100%;
      }
    }
    .accessories-box {
      .tab-right {
        display: block;
        .accessories-items {
          width: 100%;
          margin: 0;
          .product-img {
            .img-box {
              width: 100%;
              .transition();
            }
          }
        }
      }
    }
    .download-box {
      .download-tab {
        .download-items {
          min-width: auto;
          height: 26px;
          line-height: 26px;
        }
      }
      .download-table-box {
        font-size: 12px;
         th,
         td {
           padding: 12px 7px;
         }
      }
    }
  }
}
</style>
