<template>
  <div v-if="data && data.length" class="products-videos">
    <public-title :data-info="titleInfo" />
    <div class="videos-box container">
      <div class="videos">
        <video
          ref="productsVideos"
          :src="data[0].video"
          :poster="data[0].image"
          controls="controls"
          x5-video-player-type="h5"
          class="video-player"
          @click="handleVideo()"
        >
        </video>
        <div
          class="video-icon-box"
          :class="{ 'fade-out': videoState }"
          @click="handleVideo()"
        >
          <i class="iconfont icon-a-playCirclebeifen1x"></i>
        </div>
      </div>
      <p class="video-text">{{ data[0].title }}</p>
    </div>
  </div>
</template>

<script>
import PublicTitle from '@/components/public/PublicTitle.vue'
export default {
  components: {
    PublicTitle
  },
  props: {
    data: {
      type: [Array, Object],
      default: () => []
    }
  },
  data() {
    return {
      titleInfo: {
        title: this.LP.lang_videos
      },
      videoState: false // 视频播放状态
    }
  },
  methods: {
    // 控制视频播放
    handleVideo() {
      this.$refs.productsVideos.play()
      this.videoState = true
    }
  }
}
</script>

<style lang="less" scoped>
.products-videos {
  .videos-box {
    max-width: 849px;
    margin: 0 auto;
    .videos {
      height: 477px;
      position: relative;
      cursor: pointer;
      .video-player {
        width: 100%;
        height: 100%;
        border-radius: 10px;
      }
      .video-icon-box {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        &>.iconfont {
          font-size: 64px;
          color: #fff;
          transition: all 0.3s;
        }
      }
      .fade-out {
        display: none;
      }
      &:hover {
        .video-icon-box {
          &>.iconfont {
            opacity: 0.7;
          }
        }
      }
    }
    .video-text {
      font-size: 18px;
      font-weight: 600;
      color: #232931;
      line-height: 18px;
      margin-top: 16px;
      text-align: center;
    }
  }
  @media screen and (max-width: 1024px) {
    .videos-box {
      max-width: 642px;
      .videos {
        height: 360px;
        .video-icon-box {
          &>.iconfont {
            font-size: 50px;
          }
        }
      }
    }
  }
   @media screen and (max-width: 768px) {
    /deep/ .public-title {
      .text-box {
        padding: 40px 0 15px;
      }
    }
    .videos-box {
      .videos {
        height: 209px;
        .video-icon-box {
          &>.iconfont {
            font-size: 43px;
          }
        }
      }
    }
  }
}
</style>
