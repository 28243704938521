<template>
  <div class="compare-pop-mobile-wrap">
    <div class="compare-container container">
      <div class="compare-products">
        <div class="name">{{ LP.lang_compare_products }}</div>
        <div class="arrow-box" @click="handleHideCompare">
          <i class="iconfont icon-a-xiala1x"></i>
        </div>
      </div>
      <ul class="compare-list">
        <li v-for="(item, index) in list" :key="index" class="items">
          <div class="img-box">
            <img :src="item.product_thumb" alt="" class="compare-img" />
          </div>
          <p class="text-box ellipsis">{{ item.product_name }}</p>
        </li>
      </ul>
    </div>
    <div class="btn-box">
      <el-button type="info" @click="handleReset">{{ LP.lang_reset }}</el-button>
      <el-button type="primary" @click="handleComparePage">{{ LP.lang_compare }}</el-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {}
  },
  methods: {
    // 关闭对比框
    handleHideCompare() {
      this.$emit('handleHideCompare')
      this.handleReset()
    },
    // 进入对比页面
    handleComparePage() {
      this.$emit('handleComparePage')
    },
    // 重置
    handleReset() {
      this.$emit('handleReset')
    }
  }
}
</script>
<style lang="less" scoped>
.compare-pop-mobile-wrap {
   width: 100%;
   min-height: 197px;
   background: rgba(35, 41, 49, 0.85);
   position: fixed;
   bottom: 0;
   left: 0;
   z-index: 999;
  .compare-container {
    padding-top: 8px;
    .compare-products {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      .name {
        font-size: 18px;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 24px;
      }
      .arrow-box {
        &>.iconfont {
          font-size: 24px;
          color: #fff;
          cursor: pointer;
        }
      }
    }
    .compare-list {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      .items {
        width: 47%;
        height: 32px;
        margin-right: 16px;
        margin-bottom: 6px;
        background: #fff;
        display: flex;
        .img-box {
          .compare-img {
            width: 32px;
            height: 32px;
          }
        }
        .text-box {
          font-size: 12px;
          color: #333333;
          line-height: 16px;
          border-top: 1px solid #DDDDDD;
          padding: 8px;
          text-align: center;
        }
        &:nth-child(2n) {
          margin-right: 0;
        }
      }
    }
  }
  .btn-box {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    /deep/ .el-button {
      width: 50%;
      border-radius: 0;
      padding: 10px 0;
      font-size: 16px;
      line-height: 21px;
    }
    /deep/ .el-button--primary {
      color: @theme;
      background-color: #fff;
      border-color: #fff;
      margin-left: 0;
    }
  }
}
</style>
