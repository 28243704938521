<template>
  <div v-if="data && data.length" class="products-download">
    <public-title :data-info="titleInfo" />
    <div class="download-box">
      <div class="line container"></div>
      <public-tabs :data-info="data" class="container" :default-index="defaultIndex" @handleTab="handleTab" />
      <ul class="download-list container">
        <li v-for="(item, index) in data[defaultIndex].download_list" :key="index" class="items">
          <span class="iconfont icon-a-language1x download-type"></span>
          <div class="download-name">
            <p class="title">{{ item.title }}</p>
            <a class="download-link" @click="downHandler(item)">
              <span class="iconfont icon-a-xiazai1x"></span>
            </a>
          </div>
        </li>
      </ul>
    </div>
    <!-- 未登录提示 -->
    <download-dialog :download-visible="downloadVisible" @close="handleClose" />
  </div>
</template>

<script>
import PublicTitle from '@/components/public/PublicTitle.vue'
import PublicTabs from '@/components/public/PublicTabs.vue'
import DownloadDialog from '@/components/support/DownloadDialog.vue'
export default {
  components: {
    PublicTitle,
    PublicTabs,
    DownloadDialog
  },
  props: {
    data: {
      type: [Array, Object],
      default: () => []
    }
  },
  data() {
    return {
      titleInfo: {
        title: this.LP.lang_download
      },
      defaultIndex: 0,
      downloadVisible: false // 未登录提示弹窗
    }
  },
  methods: {
    // tab切换
    handleTab(item, index) {
      this.defaultIndex = index
    },
    // 下载
    downHandler(item) {
      if (item.power === '1') {
        this.downloadVisible = true
      } else {
        window.open(item.download)
      }
    },
    // 关闭弹窗
    handleClose() {
      this.downloadVisible = false
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/styles/common/common.less';
.products-download {
  .download-box {
    .download-list {
      margin-top: 16px;
      .items {
        display: flex;
        align-items: center;
        background: #F4F4F5;
        border-radius: 10px;
        padding: 20px;
        cursor: pointer;
        .transition();
        &:hover {
          .shadow();
        }
        .download-type {
          font-size: 22px;
        }
        .download-name {
          width: calc(100% - 22px);
          display: flex;
          align-items: center;
          justify-content: space-between;
          .title {
            word-break: break-word;
            font-size: 14px;
            color: #303133;
            line-height: 19px;
            padding: 0 24px 0 12px;
          }
          .download-link {
            display: block;
            &>.iconfont {
              font-size: 24px;
            }
          }
        }
        &:hover {
          .download-name {
            .title {
              color: @theme;
            }
            .download-link {
              display: block;
              &>.iconfont {
                color: @theme;
              }
            }
          }
        }
      }
      .items + .items {
        margin-top: 10px;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  /deep/ .public-title {
    .text-box {
      padding: 40px 0 25px;
    }
  }
  /deep/ .public-nav-page {
    .public-nav {
      .link-container {
        .link-inside-container {
          width: 100%;
        }
      }
    }
  }
  .products-download {
    .download-box {
      .download-list {
        .items {
          padding: 11px 10px;
          .download-type {
            display: none;
          }
          .download-name {
            width: 100%;
            .title {
              padding: 0px 43px 0px 0px;
            }
          }
        }
      }
    }
  }
}
</style>

<style lang="less">
.products-download {
  .public-nav-page {
    .nav-fixed {
      position: relative;
    }
  }
}
</style>
